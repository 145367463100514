export const translations = {
  pl: {
    T_BANNER_TYPE: 'Typ banera',

    T_BANNER_TYPE_RELATED_ARTICLE: 'aktualność',
    T_BANNER_TYPE_RELATED_EXHIBITION: 'wystawa',
    T_BANNER_TYPE_RELATED_EVENT: 'wydarzenie',
    T_BANNER_TYPE_PHOTO: 'zdjęcie',
    T_BANNER_TYPE_YOUTUBE: 'youtube',
    T_BANNER_TYPE_VIDEO: 'video',

    T_CONFIG_FACEBOOK: 'Adres profilu na Facebook',
    T_CONFIG_INSTAGRAM: 'Adres profilu na Instagram',
    T_CONFIG_TWITTER: 'Adres profilu na Twitter',
    T_CONFIG_YOUTUBE: 'Adres profilu na Youtube',
    T_CONFIG_SPOTIFY: 'Adres profilu na Spotify',
    T_CONFIG_TIK_TOK: 'Adres profilu na TikTok',
    T_CONFIG_LINKED_IN: 'Adres profilu na LinkedIn',
    T_CONFIG_NEWSLETTER_API_KEY: 'GetResponse Api Key',
    T_CONFIG_NEWSLETTER_CAMPAIGN_ID: 'GetResponse Id listy',
    T_CONFIG_PODCAST_YOUTUBE_URL: 'Podcast - adres youtube',
    T_CONFIG_PODCAST_SPOTIFY_URL: 'Podcast - adres spotify',

    T_GENERAL_FIRST_LAST_NAME: 'Imie i nazwisko',
    T_GENERAL_FIRM_NAME: 'Nazwa organizacji',

    /* Inside application code */
    T_GENERAL_ARTICLE: 'Aktualność',
    T_GENERAL_ARTICLE_IMAGE: 'Obrazek do aktualności',

    T_GENERAL_RELATED_CALENDARS: 'Powiązane kalendaria',
    T_GENERAL_RELATED_ANTIQUES: 'Powiązane eksponaty',
    T_GENERAL_RELATED_ARTICLES: 'Powiązane aktualności',
    T_GENERAL_RELATED_EVENTS: 'Powiązane wydarzenia',
    T_GENERAL_RELATED_EXHIBITIONS: 'Powiązane wystawy',
    T_GENERAL_RELATED_EXHIBITION_TYPES: 'Typ wystawy',
    T_GENERAL_RELATED_EVENT_AGE_GROUPS: 'Powiązane grupy wiekowe wydarzeń',
    T_GENERAL_RELATED_KNOWLEDGES: 'Powiązane publikacje "Wiedza on-line"',

    T_GENERAL_CITY: 'Miasto',
    T_GENERAL_IS_READED: 'Przeczytane',

    T_GENERAL_MESSAGE: 'Wiadomość',
    T_GENERAL_DATES: 'Daty',

    T_GENERAL_EVENT: 'Wydarzenie',
    T_GENERAL_EVENT_IMAGE: 'Obrazek do wydarzenia',

    T_GENERAL_EXHIBITION: 'Wystawa',
    T_GENERAL_EXHIBITION_IMAGE: 'Obrazek do wystawy',

    T_GENERAL_PHONE: 'Telefon',
    T_GENERAL_EMAIL: 'Email',
    T_GENERAL_FILE_AUDIO: 'Plik mp3',
    T_GENERAL_FIRST_NAME: 'Imię',
    T_GENERAL_LAST_NAME: 'Nazwisko',

    T_GENERAL_GUIDE: 'Wystawa',
    T_GENERAL_GUIDE_IMAGE: 'Zdjęcie przewodnika',
    T_GENERAL_GUIDE_LANGUAGES: 'Języki przewodnika',
    T_GENERAL_GUIDE_PATHS: 'Ścieżki przewodnika',

    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_FILE_TITLE: 'Opis pliku',

    T_GENERAL_PAGE_IMAGE: 'Obrazek dla strony',
    T_GENERAL_PLACE: 'Miejsce',

    T_GENERAL_TYPE: 'Rodzaj',
    T_GENERAL_IS_ON_LINE: 'On-line',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy napewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odswież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_DATE_TIME: 'Data/godzina',
    T_GENERAL_DATE_TIME_FROM: 'Data/godzina rozpoczęcia',
    T_GENERAL_DATE_TIME_TO: 'Data/godzina zakończenia',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_1_2_COLUMNS: 'Blok tekstowy (1 lub 2 kolumny)',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO: 'Blok tekstowy ze zdjęciem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_QUOTATION: 'Blok tekstowy z cytatem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK: 'Blok tekstowy z linkiem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS: 'Blok tekstowy z sekcjami',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS_LINKS:
      'Blok tytułem i sekcjami linków',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS_PHOTOS:
      'Blok tytułem i sekcjami zdjęć',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_TWO_BUTTON_LINKS:
      'Blok tekstowy z dwoma tytułami, tekstami i linkami',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_TWO_LINKS:
      'Blok tekstowy z dwoma tytułami, podtytułami, tekstami i linkami',
    T_GENERAL_BLOCK_TYPE_AUDIO: 'Blok z nagraniem audio',
    T_GENERAL_BLOCK_TYPE_VIDEO: 'Blok z nagraniem video',
    T_GENERAL_BLOCK_TYPE_YOUTUBE: 'Blok z filmem Youtube',
    T_GENERAL_BLOCK_TYPE_VIMEO: 'Blok z filmem Vimeo',
    T_GENERAL_BLOCK_TYPE_AUTHOR: 'Blok "autor"',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FILE: 'Blok z zestawem plików',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FILE_SIMPLE: 'Blok z zestawem plików',
    T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY_SIMPLE: 'Blok z galerią',
    T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY: 'Blok z galerią',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ: 'Blok z FAQ',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ_SIMPLE: 'Blok z FAQ',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FORM: 'Blok z formularzem',
    T_GENERAL_BLOCK_TYPE_LOGO_KIT: 'Blok z zestawem logotypów',
    T_GENERAL_BLOCK_TYPE_MENU: 'Blok z menu',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_FILE: 'Blok tekstowy z plikiem',
    T_GENERAL_BLOCK_TYPE_VISIT_INFO: 'Zaplanuj wizytę - Informacje',
    T_GENERAL_BLOCK_TYPE_AVAILABILITY: 'Blok "Dostępność"',
    T_GENERAL_BLOCK_TYPE_OPENING_HOURS: 'Blok "Godziny otwarcia"',
    T_GENERAL_BLOCK_MEDIA_POSITION_LEFT: 'Po lewej od tekstu',
    T_GENERAL_BLOCK_MEDIA_POSITION_TOP: 'Pod tekstem',
    T_GENERAL_BLOCK_MEDIA_POSITION_RIGHT: 'Po prawej od tekstu',
    T_GENERAL_BLOCK_MEDIA_POSITION_FULL_WIDTH: 'Pełna szerokość',
    T_GENERAL_BLOCK_MEDIA_POSITION_FULL_WIDTH_MARGIN:
      'Pełna szerokość + margines',
    T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER: 'Slider',
    T_GENERAL_BLOCK_GALLERY_TYPE_TILES: 'Kafelki',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy napewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Bloki śródtekstowe',
    T_GENERAL_ALT: 'Znacznik alt zdjęcia',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy napewno usunąć plik?',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_URL: 'Adres URL',
    T_GENERAL_AUDIO_FILE: 'Plik audio',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_VIDEO_FILE: 'Plik z filmem video',
    T_GENERAL_AUDIO_DESC: 'Link do audiodeskrypcji',
    T_GENERAL_MEDIA_VIDEO: 'Plik video',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Opublikowany',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Ukryty',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowalna',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    //T_GENERAL_PASSWORD_WAS_RESETTED: 'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_PASSWORD_WAS_RESETTED: ' Ustaw nowe hasło, aby aktywować konto.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_PREVIEW: 'Podgląd',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Opublikowany',
    T_GENERAL_UNPUBLISH: 'Ukryty',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_GENERAL_TICKET_LINK: 'Link do zakupu biletu',
    T_GENERAL_STARTING: 'Rozpoczęcie',
    T_GENERAL_ENDING: 'Zakończenie',

    T_IS_ARCHIVE: 'Archiwum',
    T_IS_VISIT_COORDINATOR: 'Koordynator zwiedzania',
    T_IS_GUIDE_SERVICE_COORDINATOR: 'Kordynator obsługi przewodnickiej',
    T_IS_VISIBLE_ON_GUIDES: 'Widoczny w "Przewodnikach"',
    T_IS_LOGGABLE_TO_GUIDES: 'Może się logować do "Strefy Przewodnika"',
    T_IS_SHIFT_COORDINATOR: 'Koordynator zmiany',
    T_IS_EDUCATION_EVENT: 'Wydarzenie edukacyjne',
    T_IS_PROMOTED: 'Promowane',
    T_IS_TOMORROWS_PATRIOTISM: 'Patriotyzm jutra',
    T_SHOW_ON_CONTACT_PAGE: 'Pokaż na stronie Kontakt',

    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',

    T_MODULE_QUIZ: 'Quiz',

    T_MODULE_BANNERS: 'Banery na stronie głównej',
    T_MODULE_BANNERS_NEW_BUTTON: 'Dodaj baner',

    T_MODULE_CONFIG: 'Konfiguracja',
    T_MODULE_CONFIG_VOLUNTEERING: 'Wolontariat',
    T_MODULE_CONFIG_TOMORROWS_PATRIOTISM: 'Patriotyzm jutra',
    T_MODULE_CONFIG_GUIDE: 'Przewodnicy',
    T_MODULE_CONFIG_CONTACT: 'Informacje kontaktowe',
    T_MODULE_DASHBOARD: 'Dashboard',

    T_MODULE_EDUCATION_TARGETS: 'Edukacja - Grupy docelowe',
    T_MODULE_EDUCATION_TARGETS_ITEMS_LIST: 'Lista grup docelowych:',
    T_MODULE_EDUCATION_TARGETS_NEW_BUTTON: 'Dodaj grupę docelową',
    T_MODULE_EDUCATION_TARGETS_AGE_GROUPS_LIST: 'Lista grup wiekowych:',

    T_MODULE_HOLIDAYS: 'Święta',
    T_MODULE_HOLIDAYS_NEW_BUTTON: 'Dodaj święto',

    T_MODULE_EDUCATION_AGE_GROUPS: 'Edukacja - Grupy wiekowe',
    T_MODULE_EDUCATION_AGE_GROUPS_NEW_BUTTON: 'Dodaj grupę wiekową',
    T_MODULE_EDUCATION_AGE_GROUPS_ACTIVITIES_LIST: 'Lista aktywności:',

    T_MODULE_EDUCATION_ACTIVITIESS: 'Edukacja - Grupy wiekowe',
    T_MODULE_EDUCATION_ACTIVITIES_NEW_BUTTON: 'Dodaj aktywność',

    T_MODULE_JOB_OFFERS: 'Oferty pracy',
    T_MODULE_JOB_OFFERS_NEW_BUTTON: 'Dodaj ofertę',
    T_MODULE_JOB_OFFERS_LIST: 'Lista ofert',

    T_MODULE_OFFER_INQUIRIES: 'Zapytania ofertowe',
    T_MODULE_OFFER_INQUIRIES_NEW_BUTTON: 'Dodaj nowe zapytanie',

    T_MODULE_PUBLIC_TENDERS: 'Przetargi publiczne',
    T_MODULE_PUBLIC_TENDERS_NEW_BUTTON: 'Dodaj nowy przetarg',

    T_MODULE_LOGO_KITS: 'Zestawy logotypów',
    T_MODULE_LOGO_KITS_NEW_BUTTON: 'Dodaj nowy zestaw',
    T_MODULE_LOGO_KIT_ITEMS_NEW_BUTTON: 'Dodaj logo',

    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_PACKAGE_GALLERIES: 'Lista galerii',
    T_MODULE_PACKAGE_GALLERIES_NEW_BUTTON: 'Dodaj nową galerię',
    T_MODULE_PACKAGE_GALLERY_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON: 'Dodaj nowe zdjęcie',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele zdjęć',

    T_MODULE_PACKAGE_LOGOS: 'Lista paczek logotypów',
    T_MODULE_PACKAGE_LOGOS_NEW_BUTTON: 'Dodaj nową paczkę',
    T_MODULE_PACKAGE_LOGO_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_LOGO_ITEMS_NEW_BUTTON: 'Dodaj logotyp',
    T_MODULE_PACKAGE_LOGO_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele logotypów',

    T_MODULE_PACKAGE_FILES: 'Lista pakietów plików',
    T_MODULE_PACKAGE_FILES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_FAQS: 'Lista FAQ',
    T_MODULE_PACKAGE_FAQS_NEW_BUTTON: 'Dodaj nowy FAQ',
    T_MODULE_PACKAGE_FAQ_ITEMS: 'Sekcje',
    T_MODULE_PACKAGE_FAQ_ITEMS_NEW_BUTTON: 'Dodaj nową sekcję',
    T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS: 'Lista pytań',
    T_MODULE_PACKAGE_FORMS: 'Lista formularzy',
    T_MODULE_PACKAGE_FORMS_NEW_BUTTON: 'Dodaj nowy formularz',
    T_MODULE_PACKAGE_FORM_ITEMS_NEW_BUTTON: 'Dodaj nowy element',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_TEXT: 'Pole tekstowe krótkie',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_TEXTAREA: 'Pole tekstowe długie',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_RADIO: 'Grupa radio buttonów',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_CHECKBOX: 'Grupa checkboxów',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',

    T_MODULE_ANTIQUE_SLIDES: 'Zdjęcia eksponatu',
    T_MODULE_ANTIQUES: 'Eksponaty',
    T_MODULE_ANTIQUES_LIST: 'Lista eksponatów:',
    T_MODULE_ANTIQUES_NEW_BUTTON: 'Dodaj eksponat',
    T_MODULE_ANTIQUES_SLIDES_NEW_BUTTON: 'Dodaj zdjęcie eksponatu',
    T_MODULE_ANTIQUE_SLIDES_LIST: 'Lista zdjęć eksponatu:',

    T_MODULE_CALENDAR_SLIDES: 'Zdjęcia kalendarium',
    T_MODULE_CALENDARS: 'Kalendarium',
    T_MODULE_CALENDARS_NEW_BUTTON: 'Dodaj wydarzenie historyczne',
    T_MODULE_CALENDARS_LIST: 'Lista wydarzeń historycznych:',
    T_MODULE_CALENDARS_SLIDES_NEW_BUTTON: 'Dodaj zdjęcie kalendarium',

    T_MODULE_ARTICLES: 'Aktualności',
    T_MODULE_ARTICLES_LIST: 'Lista aktualności:',
    T_MODULE_ARTICLES_NEW_BUTTON: 'Dodaj nową aktualność',
    T_MODULE_ARTICLES_SLIDES_LIST: 'Lista slajdów:',
    T_MODULE_ARTICLES_SLIDES_NEW_BUTTON: 'Dodaj nowy slajd w artykule',

    T_MODULE_COORDINATORS: 'Lista koordynatorów',
    T_MODULE_COORDINATORS_NEW_BUTTON: 'Dodaj nowego koordynatora',

    T_MODULE_EVENTS: 'Wydarzenia',
    T_MODULE_EVENTS_LIST: 'Lista wydarzeń:',
    T_MODULE_EVENTS_NEW_BUTTON: 'Dodaj nowe wydarzenie',

    T_MODULE_EVENT_TYPE: 'Typ wydarzenia',
    T_MODULE_EVENT_TYPES: 'Typy wydarzeń',
    T_MODULE_EVENT_TYPES_NEW_BUTTON: 'Dodaj nowy typ',

    T_MODULE_EVENT_DATES: 'Terminy',
    T_MODULE_EVENT_DATES_NEW_BUTTON: 'Dodaj termin',
    T_MODULE_EVENT_DATES_ITEMS_LIST: 'Lista terminów',

    T_MODULE_EVENT_AGE_GROUP: 'Grupa wiekowa',
    T_MODULE_EVENT_AGE_GROUPS: 'Grupy wiekowe wydarzeń',
    T_MODULE_EVENT_AGE_GROUPS_NEW_BUTTON: 'Dodaj nową grupe wiekową',

    T_MODULE_COOPERATION_MESSAGES: 'Współpraca - wiadomości',
    T_MODULE_COOPERATION_MESSAGES_NEW_BUTTON: 'Nowa wiadomość',
    T_MODULE_COOPERATION_TYPE: 'Typ współpracy',
    T_MODULE_COOPERATION_TYPES: 'Typy współpracy',
    T_MODULE_COOPERATION_TYPES_NEW_BUTTON: 'Dodaj nowy typ współpracy',

    T_MODULE_VISIT_PLAN_MESSAGES: 'Zaplanuj wizytę - wiadomości',
    T_MODULE_VISIT_PLAN_MESSAGES_NEW_BUTTON: 'Nowa wiadomość',

    T_MODULE_EXHIBITIONS: 'WYSTAWY',
    T_MODULE_EXHIBITIONS_LIST: 'Lista wystaw:',
    T_MODULE_EXHIBITIONS_NEW_BUTTON: 'Dodaj nową wystawę',

    T_MODULE_EXHIBITION_TYPES: 'Typy wystaw',
    T_MODULE_EXHIBITION_TYPES_NEW_BUTTON: 'Dodaj nowy typ wystawy',

    T_MODULE_GUIDES: 'Lista przewodników',
    T_MODULE_GUIDES_NEW_BUTTON: 'Dodaj nowego przewodnika',

    T_MODULE_GUIDE_LANGUAGES: 'Lista języków oprowadzania',
    T_MODULE_GUIDE_LANGUAGES_NEW_BUTTON: 'Dodaj nowy język',

    T_MODULE_GUIDE_PATHS: 'Lista tras oprowadzania',
    T_MODULE_GUIDE_PATHS_NEW_BUTTON: 'Dodaj nową trasę',

    T_MODULE_TEAM_ITEMS: 'Członkowie zespołu',
    T_MODULE_TEAM_ITEMS_NEW_BUTTON: 'Dodaj członka zespołu',

    T_MODULE_TEAM_SECTION: 'Sekcja',
    T_MODULE_TEAM_SECTIONS: 'Sekcje',
    T_MODULE_TEAM_SECTIONS_NEW_BUTTON: 'Dodaj sekcję',

    T_MODULE_KNOWLEDGES: 'Wiedza on-line',
    T_MODULE_KNOWLEDGES_LIST: 'Lista publikacji:',
    T_MODULE_KNOWLEDGES_NEW_BUTTON: 'Dodaj nową publikację',
    T_MODULE_KNOWLEDGES_SLIDES_NEW_BUTTON: 'Dodaj nowy slajd',
    T_MODULE_KNOWLEDGES_SLIDES_LIST: 'Lista slajdów:',

    T_MODULE_KNOWLEDGE_TARGET: 'Grupa docelowa',

    T_MODULE_KNOWLEDGE_VARIOUS_PROPERTIES: 'Właściwości',
    T_MODULE_KNOWLEDGE_PUBLICATION_TYPE: 'Typ publikacji',
    T_MODULE_KNOWLEDGE_PUBLICATION_TYPES: 'Typy publikacji',
    T_MODULE_KNOWLEDGE_PUBLICATION_TYPES_ADDITIONAL:
      'Dodatkowe typy publikacji',
    T_MODULE_KNOWLEDGE_PUBLICATION_TYPES_NEW_BUTTON: 'Dodaj nowy typ',

    T_MODULE_KNOWLEDGE_TARGETS: 'Grupy docelowe',
    T_MODULE_KNOWLEDGE_TARGETS_NEW_BUTTON: 'Dodaj nową grupę',

    T_MODULE_KNOWLEDGE_EPOCHS: 'Epoki',
    T_MODULE_KNOWLEDGE_EPOCHS_NEW_BUTTON: 'Dodaj nową epokę',

    T_MODULE_MARKET_ADVICES: 'Konsultacje rynkowe',
    T_MODULE_MARKET_ADVICES_NEW_BUTTON: 'Dodaj nową konsultację',

    T_MODULE_KNOWLEDGE_GENRES: 'Kategorie',
    T_MODULE_KNOWLEDGE_GENRES_NEW_BUTTON: 'Dodaj nową kategorię',

    T_MODULE_OPENING_HOURS: 'Godziny otwarcia',
    T_MODULE_OPENING_HOURS_NEW_BUTTON: 'Dodaj godziny otwarcia',

    T_MODULE_MENUS: 'Menusy',
    T_MODULE_MENUS_LIST: 'Lista menusów:',
    T_MODULE_MENUS_NEW_BUTTON: 'Utwórz nowe menu',
    T_MODULE_MENU_ITEMS_NEW_BUTTON: 'Utwórz nowy link w menu',
    T_MODULE_MENU_ITEMS_ITEMS_LIST: 'Lista pozycji w menu:',
    T_MODULE_MENU_RELATED_PAGE: 'Podstrona',
    T_MODULE_MENU_RELATED_ARTICLE: 'Artykuł',
    T_MODULE_MENU_RELATED_EVENT: 'Wydarzenie',
    T_MODULE_MENU_RELATED_EXHIBITION: 'Wystawa',

    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_PAGE_IS_REDIRECT: 'przekierowanie',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SHOW_IN_MAIN_MENU: '[SA] Menu główne',
    T_MODULE_PAGES_SHOW_IN_ADDITIONAL_MENU: '[SA] Menu dodatkowe',
    T_MODULE_PAGES_SHOW_IN_FOOTER_MENU: '[SA] Menu stopki',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MODULE_PAGES_REDIRECT_TO_PAGE: 'Przekieruj na stronę',
    T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE: 'Pliki:',
    T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE: 'pliki',
    T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS_HEADER_TITLE: 'Pytania:',
    T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS_EXPAND_TITLE: 'pytania',
    T_MODULE_PACKAGE_FORM_ITEM_VARIANTS_HEADER_TITLE: 'Podelementy:',
    T_MODULE_PACKAGE_FORM_ITEM_VARIANTS_EXPAND_TITLE: 'podelementy',

    T_MODULE_QUIZZES: 'Quizy',
    T_MODULE_QUIZZES_ITEMS_LIST: 'Lista quizów:',
    T_MODULE_QUIZZES_ADD_NEW_BUTTON: 'Dodaj nowy quiz',
    T_MODULE_QUIZZES_QUESTIONS_LIST: 'Lista pytań w quizie:',
    T_MODULE_QUIZZES_NEW_QUESTION_BUTTON: 'Dodaj pytanie do quizu',

    T_MODULE_QUIZ_QUESTIONS_ANSWERS_LIST: 'Lista odpowiedzi do pytania:',
    T_MODULE_QUIZ_QUESTIONS_NEW_ANSWER_BUTTON: 'Dodaj odpowiedź do pytania',

    T_MODULE_PROCUREMENTS: 'Działalność kulturalna',
    T_GENERAL_PROCUREMENT_IMAGE: 'Obrazek',
    T_MODULE_PROCUREMENTS_NEW_BUTTON: 'Dodaj zamówienie',
    T_MODULE_PROCUREMENTS_SLIDES_NEW_BUTTON: 'Dodaj slajd do zamówienia',

    T_MODULE_SALE_OFFERS: 'Ogłoszenia sprzedaży',
    T_GENERAL_SALE_OFFER_IMAGE: 'Obrazek',
    T_MODULE_SALE_OFFERS_NEW_BUTTON: 'Dodaj ogłoszenie',
    T_MODULE_SALE_OFFERS_SLIDES_NEW_BUTTON: 'Dodaj slajd do ogłoszenia',

    T_MODULE_SPONSOR_FIRMS: 'Darczyńcy - organizacje',
    T_MODULE_SPONSOR_FIRMS_NEW_BUTTON: 'Dodaj nowego darczyńcę',
    T_MODULE_SPONSOR_PERSONS: 'Darczyńcy - osoby',
    T_MODULE_SPONSOR_PERSONS_NEW_BUTTON: 'Dodaj nowego darczyńcę',

    T_MODULE_VISIT_INFOS: 'Informacje',
    T_MODULE_VISIT_INFOS_NEW_BUTTON: 'Dodaj informację',

    T_MODULE_MEDIATEKAS_LIST: 'Lista obiektów',
    T_MODULE_MEDIATEKA_SLIDES_LIST: 'Lista slajdów',
    T_MODULE_MEDIATEKAS_SLIDES_NEW_BUTTON: 'Dodaj slajd',

    T_MODULE_MEDIATEKA_TAGS: 'Mediateka - tagi',
    T_MODULE_MEDIATEKA_TAGS_NEW_BUTTON: 'Dodaj tag',

    T_MODULE_MEDIATEKA_COLLECTIONS: 'Mediateka - kolekcje',
    T_MODULE_MEDIATEKA_COLLECTIONS_NEW_BUTTON: 'Dodaj kolekcję',

    T_MODULE_MEDIATEKAS: 'Mediateka - obiekty',
    T_MODULE_MEDIATEKAS_NEW_BUTTON: 'Dodaj obiekt',

    T_MODULE_MESSENGER: 'Wiadomości',
    T_MODULE_MESSENGER_RECIPIENT: 'Odbiorca',
    T_MODULE_MESSENGER_SEND_TO: 'Napisz do',
    T_MODULE_MESSENGER_GO_FURTHER: 'Przejdź dalej',
    T_MODULE_MESSENGER_THREAD: 'Wątek',
    T_MODULE_MESSENGER_MESSAGE: 'Wiadomość',
    T_MODULE_MESSENGER_WITH: 'z',
    T_MODULE_MESSENGER_SENT: 'Wiadomość wysłana',
    T_MODULE_MESSENGER_MORE: 'Więcej',
    T_MODULE_MESSENGER_UPDATED_AT: 'Aktualizacja',
    T_MODULE_MESSENGER_YOU: 'Ty',
    T_MENU_CONTENTS: 'Treści',
    T_MENU_PACKAGES: 'Pakiety',
    T_MENU_MENUS: 'Menusy',
    T_MENU_REGISTRIES: 'Rejestry',
    T_MENU_GUIDES: 'Przewodnicy',
    T_MENU_EDUCATION: 'Edukacja',
    T_MENU_MESSAGES: 'Wiadomości',
    T_MENU_TEAM: 'Zespół',
    T_MENU_KNOWLEDGE: 'Wiedza on-line',
    T_MENU_BIP: 'BIP',
    T_MENU_SUPPORT_US: 'Wesprzyj nas',
    T_MENU_PLAN_VISIT: 'Zaplanuj wizytę',
    T_MENU_MEDIATEKA: 'Mediateka',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PATCH: 'Edycja',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktaulizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_HOMEPAGE_POSITION: 'Nr pozycji na SG',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_VALIDATION_ALLOWED_EXTENSIONS: 'Dozwolonie rozszerzenia to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    /* Thumbs */
    T_THUMB_BANNER_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_BANNER_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_BANNER_THUMBS_BIG: 'Kadr duży',
    T_THUMB_PAGE_THUMBS_PODCAST: 'Kadr podcast',

    T_THUMB_PAGE_BLOCK_THUMBS_BLOCK: 'Kadr bloku',
    T_THUMB_PAGE_BLOCK_THUMBS_SLIDER: 'Kadr slidera',
    T_THUMB_PAGE_BLOCK_SECTION_THUMBS_BLOCK: 'Kadr sekcji',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_BLOCK: 'Kadr bloku',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_SLIDER: 'Kadr slidera',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_TEST: 'Kadr testowy',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_BIG: 'Kadr duży',

    T_THUMB_ARTICLE_BLOCK_SECTION_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_ARTICLE_BLOCK_SECTION_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_ARTICLE_BLOCK_SECTION_THUMBS_BIG: 'Kadr duży',

    T_THUMB_PAGE_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_PAGE_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_PAGE_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_PAGE_THUMBS_BIG: 'Kadr duży',

    T_THUMB_PAGE_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_PAGE_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_PAGE_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_PAGE_BLOCK_THUMBS_NORMALL_FULL: 'Kadr standardowy',
    T_THUMB_PAGE_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_PAGE_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_ARTICLE_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_ARTICLE_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_ARTICLE_THUMBS_BIG: 'Kadr duży',

    T_THUMB_ARTICLE_SLIDE_THUMBS_VERTICAL: 'Kadr pionowy',

    T_THUMB_ARTICLE_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_ARTICLE_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_ARTICLE_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_ARTICLE_BLOCK_THUMBS_NORMALL: 'Kadr standardowy',
    T_THUMB_ARTICLE_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_ARTICLE_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_EVENT_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_EVENT_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_EVENT_THUMBS_LIST: 'Kadr listy',
    T_THUMB_EVENT_THUMBS_BIG: 'Kadr duży',
    T_THUMB_EVENT_THUMBS_BANNER: 'Kadr banner',

    T_THUMB_EVENT_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_EVENT_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_EVENT_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_EVENT_BLOCK_THUMBS_NORMALL_FULL: 'Kadr standardowy',
    T_THUMB_EVENT_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_EVENT_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_EXHIBITION_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_EXHIBITION_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_EXHIBITION_THUMBS_LIST: 'Kadr listy',
    T_THUMB_EXHIBITION_THUMBS_BIG: 'Kadr duży',
    T_THUMB_EXHIBITION_THUMBS_BANNER: 'Kadr banner',

    T_THUMB_EXHIBITION_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_EXHIBITION_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_EXHIBITION_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_EXHIBITION_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_ANTIQUE_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_ANTIQUE_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_ANTIQUE_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_ANTIQUE_BLOCK_THUMBS_NORMALL_FULL: 'Kadr standardowy',
    T_THUMB_ANTIQUE_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_ANTIQUE_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_ANTIQUE_SLIDE_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_ANTIQUE_SLIDE_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_ANTIQUE_SLIDE_THUMBS_INDEX3: 'Kadr index1',
    T_THUMB_ANTIQUE_SLIDE_THUMBS_INDEX2: 'Kadr index2',
    T_THUMB_ANTIQUE_SLIDE_THUMBS_INDEX1: 'Kadr index3',
    T_THUMB_ANTIQUE_SLIDE_THUMBS_BIG: 'Kadr duży',

    T_THUMB_CALENDAR_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_CALENDAR_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_CALENDAR_THUMBS_LIST: 'Kadr listy',
    T_THUMB_CALENDAR_THUMBS_BIG: 'Kadr duży',
    T_THUMB_CALENDAR_THUMBS_BANNER: 'Kadr banner',

    T_THUMB_CALENDAR_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_CALENDAR_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_CALENDAR_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_CALENDAR_BLOCK_THUMBS_NORMALL_FULL: 'Kadr standardowy',
    T_THUMB_CALENDAR_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_CALENDAR_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_EDUCATION_ACTIVITY_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_EDUCATION_ACTIVITY_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_EDUCATION_ACTIVITY_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_EDUCATION_ACTIVITY_BLOCK_THUMBS_NORMALL_FULL: 'Kadr standardowy',
    T_THUMB_EDUCATION_ACTIVITY_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_EDUCATION_ACTIVITY_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_KNOWLEDGE_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_KNOWLEDGE_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_KNOWLEDGE_THUMBS_INDEX: 'Kadr główny',
    T_THUMB_KNOWLEDGE_THUMBS_BIG: 'Kadr duży',
    T_THUMB_KNOWLEDGE_THUMBS_BANNER: 'Kadr banner',

    T_THUMB_KNOWLEDGE_BLOCK_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_KNOWLEDGE_BLOCK_THUMBS_SQUARE: 'Kadr kwadratowy',
    T_THUMB_KNOWLEDGE_BLOCK_THUMBS_SMALL_FULL: 'Kadr mały',
    T_THUMB_KNOWLEDGE_BLOCK_THUMBS_NORMALL_FULL: 'Kadr standardowy',
    T_THUMB_KNOWLEDGE_BLOCK_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_KNOWLEDGE_BLOCK_THUMBS_BIG: 'Kadr duży',

    T_THUMB_LOGO_KIT_ITEM_THUMBS_VERTICAL: 'Kadr mały',
    T_THUMB_LOGO_KIT_ITEM_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_LOGO_KIT_ITEM_THUMBS_BIG: 'Kadr duży',

    T_THUMB_QUIZ_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_QUIZ_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_QUIZ_THUMBS_BIG: 'Kadr duży',

    T_THUMB_QUIZ_QUESTION_THUMBS_VERTICAL: 'Kadr pionowy',
    T_THUMB_QUIZ_QUESTION_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_QUIZ_QUESTION_THUMBS_BIG: 'Kadr duży',

    T_THUMB_SPONSOR_FIRM_THUMBS_SMALL: 'Kadr mały',
    T_THUMB_SPONSOR_FIRM_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_SPONSOR_FIRM_THUMBS_BIG: 'Kadr duży',

    T_THUMB_SPONSOR_PERSON_THUMBS_SMALL: 'Kadr mały',
    T_THUMB_SPONSOR_PERSON_THUMBS_MEDIUM: 'Kadr średni',
    T_THUMB_SPONSOR_PERSON_THUMBS_BIG: 'Kadr duży',

    T_THUMB_MEDIATEKA_COLLECTION_THUMBS_PANELLIST: 'Lista w panelu',
    T_THUMB_MEDIATEKA_THUMBS_PANELLIST: 'Lista w panelu',

    T_HOLIDAY_IS_FREE_ENTRY: 'Wstęp wolny',
    T_HOLIDAY_IS_CLOSED: 'Zamknięte',

    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',

    'BIP - Procurement': 'BIP - Działalność kulturalna',
    'BIP - Sale offer': 'BIP - Ogłoszenia sprzedaży',
    'BIP - Offer inquiry': 'BIP - Zapytania ofertowe',
    'BIP - Public Tender': 'BIP - Przetargi publiczne',
    'BIP - Market Advice': 'BIP - Konsultacje rynkowe',
    'BIP - Job Offer': 'BIP - Oferty pracy',

    'BIP - Job Offer': 'BIP - Oferty pracy',

    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'File packages': 'Pliki',
    'File package items': 'Elementy pakietu plików',
    'Gallery packages': 'Galerie',
    'Gallery package items': 'Elementy galerii',
    'Gallery package item thumbs': 'Kadry elementu galerii',
    'Faq packages': 'FAQ',
    'Faq package sections': 'Sekcje FAQ',
    'Faq package section questions': 'Pytania sekcji FAQ',
    'Form packages': 'Formularze',
    'Form package elements': 'Elementy formularza',
    'Form package element variants': 'Podelementy elementu formularza',
    'Translator entries': 'Tłumaczenia',
    Articles: 'Aktualności',
    Banners: 'Banery na stronie głównej',
    EducationTargets: 'Grupy docelowe',
    EducationAgeGroups: 'Grupy wiekowe',
    Events: 'Wydarzenia',
    'Event age groups': 'Wydarzenia - grupy wiekowe',
    Exhibitions: 'Wystawy',
    'Guides list': 'Lista przewodników',
    'Guide languages list': 'Języki oprowadzania',
    'Guide paths list': 'Ścieżki oprowadzania',
    'Page block sections': 'Sekcje bloku strony',
    'Page block section thumbs': 'Kadry sekcji bloku strony',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    Guides: 'Przewodnicy',
    'Guide languages': 'Przewodnicy - języki oprowadzania',
    'Guide paths': 'Przewodnicy - trasy oprowadzania',
    'Is enabled': 'Aktywny',
    Knowledges: 'Publikacje',
    Menus: 'Menusy',
    'Menu items': 'Menusy - elementy',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Title of article': 'Tytuł aktualności',
    'Text of article': 'Zawartość aktualności',
    'Title of education target': 'Nazwa edukacyjnej grupy docelowej',
    'Text of education target': 'Opis edukacyjnej grupy docelowej',
    'Title of education age group': 'Nazwa edukacyjnej grupy wiekowej',
    'Title of education activity': 'Nazwa aktywności edukacyjnej',
    'Title of event': 'Tytuł wydarzenia',
    'Text of event': 'Zawartość wydarzenia',
    'Title of exhibition': 'Tytuł wystawy',
    'Text of exhibition': 'Zawartość wystawy',
    'Title of guide language': 'Nazwa języka',
    'Title of guide path': 'Tytuł ścieżki',

    'First name': 'Imię',
    'Last name': 'Nazwisko',
    Phone: 'Telefon',
    LicenceNumber: 'Numer licencji',

    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    'File package': 'Pakiet plików',
    'Gallery package': 'Pakiet galerii',
    'Logo package': 'Pakiet logotypów',
    'Faq package': 'Pakiet FAQ',
    'Form package': 'Pakiet formularza',
    Sections: 'Sekcje',
    'Sections list': 'lista sekcji - edycja',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nie odnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins.': 'Logowanie zablokowane na 15 minut',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',
    'Cooperation messages list': 'Lista wiadomości',
    Antiques: 'Eksponaty',
    Calendars: 'Kalendarium',
    'Calendar slides': 'Kalendarium - slajdy',
    'Cooperation messages': 'Wiadomości - współpraca',
    'Cooperation types': 'Wiadomości - typy współpracy',
    Coordinators: 'Koordynatorzy',
    'Education activities': 'Edukacja - typy aktywności',
    'Education age groups': 'Edukacja - grupy wiekowe',
    'Education targets': 'Edukacja - grupy docelowe',
    'Event dates': 'Wydarzenia - terminy',
    'Event types': 'Wydarzenia - typy',
    'Exhibition types': 'Wystawy - typy',

    Knowledge: 'Wiedza on-line',
    'Knowledge epochs': 'Wiedza on-line - epoki',
    'Knowledge genres': 'Baza wiedzy - kategorie',
    'Knowledge publication types': 'Baza wiedzy - typy publikacji',
    'Knowledge targets': 'Baza wiedzy - grupy docelowe',

    'Team items': 'Zespół - członkowie',
    'Team sections': 'Zespół - sekcje',
    'Visit plan messages': 'Wiadomości - zaplanuj wizytę',
    Config: 'Konfiguracja',
    'Config Volunteerings': 'Konfiguracja - Wolontariat',

    knowledgePublicationTypeTitle: 'Typ',
    knowledgePublicationTypesAdditional: 'Typy dod.',
    knowledgeGenresTitles: 'Kategorie',
    knowledgeTargetsTitles: 'Grupy docelowe',
    knowledgeEpochsTitles: 'Epoki',
    'Sponsor firms': 'Darczyńcy - firmy, instytucje',
    'Sponsor persons': 'Darczyńcy - osoby',
    'Visit infos': 'Informacje',

    Quizzes: 'Quizy',
    JobOffers: 'Oferty pracy',
    'Title of publicTender': 'Tytuł',
    'Text of publicTender': 'Treść',
    'Title of offerInquiry': 'Tytuł',
    'Text of offerInquiry': 'Treść',
    'Title of marketAdvice': 'Tytuł',
    'Text of marketAdvice': 'Treść',
    'Title of procurement': 'Tytuł',
    'Text of procurement': 'Treść',
    'Title of saleOffer': 'Tytuł',
    'Text of saleOffer': 'Treść',
  },
}
