//import _block from '../core/_schema/_block'
import _block from './_eventBlock'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'

//import articleSchema from './article'
//import eventSchema from './event'
//import exhibitionSchema from './exhibition'

const schema = {
  endpoint: '/events',
  preview: '/preview/events/:id',
  resource: {
    definition: 'Event-event:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
            type: 'textarea',
          },
          text: {
            type: 'textarea',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          alt: {},
        },
      },
      backgroundColor: {
        fullWidth: false,
        type: 'choice',
        choices: {
          'red': 'czerwony',
          'gold': 'złoty',
          'grey': 'szary',
        },
        validate: ['required'],
      },
      imageColorsMode: {
        fullWidth: false,
        type: 'choice',
        choices: {
          'colorful': 'kolorowe',
          'grayscale': 'czarno-białe',
        },
        validate: ['required'],
      },
//      map: {
//        type: 'map',
//        description: 'T_GENERAL_MAP',
//        hint: 'T_GENERAL_MAP_HINT',
//      },
//      lead: {
//        type: 'textarea',
//        description: 'T_GENERAL_LEAD',
//        hint: 'T_GENERAL_LEAD_HINT',
//        validate: ['required'],
//      },
//      description: {
//        type: 'tinymce',
//        description: 'T_GENERAL_DESCRIPTION',
//        hint: 'T_GENERAL_DESCRIPTION_HINT',
//        validate: ['required'],
//      },
//      eventAgeGroup: {
//        description: 'T_MODULE_EVENT_AGE_GROUP',
//        validate: ['required'],
//        type: 'resource',
//        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
//        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
//        endpoint: `/event_age_groups?order[ord]=desc&pagination=false`,
//      },
      eventAgeGroups: {
        description: 'T_MODULE_EVENT_AGE_GROUP',
        validate: ['required'],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/event_age_groups?order[ord]=desc&pagination=false`,
      },
      eventType: {
        description: 'T_MODULE_EVENT_TYPE',
        validate: ['required'],
        type: 'resource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/event_types?order[ord]=desc&pagination=false`,
      },

      plannedPublishDateTime: {
        type: 'datetime',
        description: 'T_GENERAL_PLANNED_PUBLISH_DATE_TIME',
        fullWidth: false,
        validate: ['required'],
      },
      isOnline:{
        type: 'boolean',
      },
      isEducation:{
        type: 'boolean',
      },
      isFree:{
        type: 'boolean',
      },
      isPromoted:{
        type: 'boolean',
      },
      hideInfoPanel:{
        type: 'boolean',
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/event_thumbs',
        },
        validate: ['maxSize'],
      },
      youtube: {
       type: 'youtube',
        fullWidth: false,
      },
      relatedArticles: {
        description: 'T_GENERAL_RELATED_ARTICLES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${articlesSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/articles?order[createdAt]=desc&pagination=false`,
      },
      relatedEvents: {
        description: 'T_GENERAL_RELATED_EVENTS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${eventSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/events?order[ord]=desc&pagination=false`,
      },
      relatedExhibitions: {
        description: 'T_GENERAL_RELATED_EXHIBITIONS',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/exhibitions?order[ord]=desc&pagination=false`,
      },
      relatedKnowledges: {
        description: 'T_GENERAL_RELATED_KNOWLEDGES',
        validate: [],
        type: 'multipleResource',
        titleAccessor: `translations.${process.env.REACT_APP_LOCALE}.title`,
        //endpoint: `${exhibitionSchema.endpoint}?order[ord]=desc&pagination=false`,
        endpoint: `/knowledges?order[ord]=desc&pagination=false`,
      },
      
    },
  },
  subresources: {
    block: {
      endpoint: '/event_blocks',
      definition: 'EventBlock-eventBlock:read',
      contextualHint: 'how_to_edit',
      types: _block(
        photoSchema.endpoint.single,
        fileSchema.endpoint.single,
        '/event_block_sections',
        '/event_block_thumbs',
        '/event_block_section_thumbs'
      ).types,
    },
  },
  
}

export default schema
