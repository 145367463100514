

import { boolean } from 'core/_helpers/boolean'
import home from 'core/pages/Home/routes'
import admins from 'core/pages/Admins/routes'
import adminGroups from 'core/pages/AdminGroups/routes'
import loginEntries from 'core/pages/LoginEntries/routes'
import registryEntries from 'core/pages/RegistryEntries/routes'
import config from 'core/pages/Config/routes'
import profile from 'core/pages/Profile/routes'
import messenger from 'core/pages/Messenger/routes'
import pages from 'core/pages/Pages/routes'
import translatorEntries from 'core/pages/TranslatorEntries/routes'
import packageFiles from 'core/pages/PackageFiles/routes'
import packageGalleries from 'core/pages/PackageGalleries/routes'
import packageFaqs from 'core/pages/PackageFaqs/routes'
import packageForms from 'core/pages/PackageForms/routes'

import antiques from 'pages/Antiques/routes'
import articles from 'pages/Articles/routes'
import calendars from 'pages/Calendars/routes'
import banners from 'pages/Banners/routes'
import configVolunteering from 'pages/ConfigVolunteering/routes'
import configGuide from 'pages/ConfigGuide/routes'
import configTomorrowsPatriotism from 'pages/ConfigTomorrowsPatriotism/routes'
import configContact from 'pages/ConfigContact/routes'
import cooperationMessages from 'pages/CooperationMessages/routes'
import cooperationTypes from 'pages/CooperationTypes/routes'
import coordinators from 'pages/Coordinators/routes'
import educationTargets from 'pages/EducationTargets/routes'
import events from 'pages/Events/routes'
import eventTypes from 'pages/EventTypes/routes'
import eventAgeGroups from 'pages/EventAgeGroups/routes'
import eventDates from 'pages/EventDates/routes'
import exhibitions from 'pages/Exhibitions/routes'
import exhibitionTypes from 'pages/ExhibitionTypes/routes'
import guides from 'pages/Guides/routes'
import guideLanguages from 'pages/GuideLanguages/routes'
import guidePaths from 'pages/GuidePaths/routes'

import jobOffers from 'pages/JobOffers/routes'

import openingHours from 'pages/OpeningHours/routes'
import holidays from 'pages/Holidays/routes'

import knowledges from 'pages/Knowledges/routes'
import knowledgeSlides from 'pages/KnowledgeSlides/routes'
import knowledgePublicationTypes from 'pages/KnowledgePublicationTypes/routes'
import knowledgeTargets from 'pages/KnowledgeTargets/routes'
import knowledgeEpochs from 'pages/KnowledgeEpochs/routes'
import knowledgeGenres from 'pages/KnowledgeGenres/routes'

import logoKits from 'pages/LogoKits/routes'
import logoKititemss from 'pages/LogoKitItems/routes'

import menus from 'pages/Menus/routes'

import procurements from 'pages/Procurements/routes'
import saleOffers from 'pages/SaleOffers/routes'
import offerInquiries from 'pages/OfferInquiries/routes'
import publicTenders from 'pages/PublicTenders/routes'
import marketAdvices from 'pages/MarketAdvices/routes'

import mediatekas from 'pages/Mediatekas/routes'
import mediatekaTags from 'pages/MediatekaTags/routes'
import mediatekaCollections from 'pages/MediatekaCollections/routes'


import quizzes from 'pages/Quizzes/routes'
import quizQuestions from 'pages/QuizQuestions/routes'
import quizAnswers from 'pages/QuizAnswers/routes'

import sponsorFirms from 'pages/SponsorFirms/routes'
import sponsorPersons from 'pages/SponsorPersons/routes'

import teamItems from 'pages/TeamItems/routes'
import teamSections from 'pages/TeamSections/routes'

import visitInfos from 'pages/VisitInfos/routes'
import visitPlanMessages from 'pages/VisitPlanMessages/routes'

import zonks from 'pages/Zonks/routes'



import {
  Person,
  MailOutline,
  CalendarToday,
  ViewListOutlined,
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  SchoolOutlined,
  CastForEducation,
  Accessibility
} from '@material-ui/icons'



const menu = () =>
  [
    home().index,
    pages().index,
    {
      title: 'T_MENU_CONTENTS',
      icon: ViewListOutlined,
      items: [
        articles().index,
        events().index,
        exhibitions().index,
        antiques().index,
        calendars().index,
        eventTypes().index,
        eventAgeGroups().index,
        exhibitionTypes().index,
        banners().index,
        //zonks().index,
      ],
    },
    {
      title: 'T_MENU_EDUCATION',
      icon: SchoolOutlined,
      items: [
        educationTargets().index,
      ],
    },
    {
      title: 'T_MENU_MEDIATEKA',
      icon: SchoolOutlined,
      items: [
        //mediatekas().index,
        mediatekaCollections().index,
        //mediatekaTags().index,
      ],
    },
    {
      title: 'T_MENU_KNOWLEDGE',
      icon: CastForEducation,
      items: [
        knowledges().index,
        quizzes().index,
        knowledgePublicationTypes().index,
        knowledgeTargets().index,
        knowledgeEpochs().index,
        knowledgeGenres().index,
      ],
    },
    {
      title: 'T_MENU_BIP',
      icon: CastForEducation,
      items: [
        procurements().index,
        saleOffers().index,
        offerInquiries().index,
        publicTenders().index,
        marketAdvices().index,
        jobOffers().index,
      ],
    },
    {
      title: 'T_MENU_PLAN_VISIT',
      icon: CalendarToday,
      items: [
        openingHours().index,
        holidays().index,
        visitInfos().index,
      ],
    },
    {
      title: 'T_MENU_SUPPORT_US',
      icon: Accessibility,
      items: [
        sponsorFirms().index,
        sponsorPersons().index,
      ],
    },
    {
      title: 'T_MENU_MESSAGES',
      icon: MailOutline,
      items: [
        visitPlanMessages().index,
        cooperationMessages().index,
        cooperationTypes().index,
      ],
    },
    {
      title: 'T_MENU_PACKAGES',
      icon: ViewListOutlined,
      items: [
        packageFiles().index,
        packageGalleries().index,
        packageFaqs().index,
        logoKits().index,
//        packageForms().index,
      ],
    },
    {
      title: 'T_MENU_MENUS',
      icon: ViewListOutlined,
      items: [
        menus().index,
      ],
    },
    {
      title: 'T_MENU_TEAM',
      icon: Person,
      items: [
        teamItems().index,
        teamSections().index,
      ],
    },
    {
      title: 'T_MENU_GUIDES',
      icon: Person,
      items: [
        guides().index,
        guideLanguages().index,
        guidePaths().index,
        coordinators().index,
      ],
    },
    translatorEntries().index,
    {
      title: 'T_MODULE_SETTINGS',
      icon: SettingsOutlined,
      items: [
          config().index, 
          configContact().index, 
          configVolunteering().index, 
          configTomorrowsPatriotism().index, 
          configGuide().index, 
          profile().index
      ],
    },
    {
      title: 'Administrators',
      icon: PeopleOutlined,
      items: [admins().index, adminGroups().index],
    },
    {
      title: 'T_MENU_REGISTRIES',
      icon: DescriptionOutlined,
      items: [registryEntries().index, loginEntries().index],
    },
  ].concat(
    // wiadomości na razie ukryte
    //boolean(process.env.REACT_APP_MESSENGER_SHOW) ? [messenger().index] : []
  )

export default menu
